import { render, staticRenderFns } from "./HeaderMedia.vue?vue&type=template&id=4e836c7a&scoped=true&"
import script from "./HeaderMedia.vue?vue&type=script&lang=js&"
export * from "./HeaderMedia.vue?vue&type=script&lang=js&"
import style0 from "./HeaderMedia.vue?vue&type=style&index=0&id=4e836c7a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e836c7a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/home/aydistribution/www/www.mybeers.fr/staging/components/VideoPlayer.vue').default})
