import { render, staticRenderFns } from "./ButtonBlobMore.vue?vue&type=template&id=107e4978&scoped=true&"
import script from "./ButtonBlobMore.vue?vue&type=script&lang=js&"
export * from "./ButtonBlobMore.vue?vue&type=script&lang=js&"
import style0 from "./ButtonBlobMore.vue?vue&type=style&index=0&id=107e4978&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107e4978",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictoPlus: require('/home/aydistribution/www/www.mybeers.fr/staging/components/PictoPlus.vue').default,ButtonBlob: require('/home/aydistribution/www/www.mybeers.fr/staging/components/ButtonComponents/ButtonBlob.vue').default})
